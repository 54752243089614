<script>
import { defineComponent } from '@vue/composition-api'

const DefaultValue = { federal_number: '', year: new Date().getFullYear(), status: '', isValid: false, isMedicalCertificateValid: false, isCompetition: false };

export default defineComponent({
    props:{
        value: { type: Object, default: DefaultValue },
        isFederal: { type: Boolean, required: true },
        forceEdit: { type: Boolean, default: false },
        shouldBeCompetitor: { type: Boolean, default: false},
        isNotSetVariant: { type: String, default: 'primary' },
        isValidVariant: { type: String, default: 'success' },
        disabled: { type: Boolean, default: false },
    },
    data(){
        return {
            lic: this.validate(this.value),
            isRequired: this.isFederal,
            mustEdit: this.forceEdit,
            competitorModeAwaited: this.shouldBeCompetitor,
            isDisabled: this.disabled,
        }
    },
    computed:{
        isError(){
            if(this.lic.federal_number == null || this.lic.federal_number == '')
                return this.isRequired;
            return !this.lic.isValid;
        },
        isWarning(){
            if(this.lic.federal_number == null || this.lic.federal_number == '' || !this.lic.isValid || !this.lic.isCompetitor)
                return this.competitorModeAwaited;
            return !this.lic.isMedicalCertificateValid;
        },
        isEditable(){
            return this.isRequired && (this.isError || this.isWarning || this.mustEdit)
        },
        isSet(){
            return this.displayNumber != '';
        },
        displayNumber(){
            if(this.lic.federal_number == null || this.lic.federal_number == '') {
                if(this.isRequired) {
                    switch(this.lic.status){
                        case "pending":
                            return this.$t('meta.licencee-status.pending');
                        case "needCheck":
                            return this.$t('meta.licencee-status.need-check');
                    }
                }
                return '';
            }
            return this.lic.federal_number;
        },
        badgeVariant(){
            return this.isError ? 'danger' : (this.isWarning ? 'warning' : (this.isNotSet ? this.isNotSetVariant : this.isValidVariant)); //'primary');
        }
    },
    methods:{
        validate(value){
            var ret = { /*...DefaultValue, */...value};
            return ret;
        },
        edit(){
            if(!this.isDisabled) this.$emit('edit')
        }
    },
    watch:{
        value(newValue){
            this.lic = this.validate(newValue);
        },
        isFederal(newValue){
            this.isRequired = newValue;
        },
        forceEdit(newValue){
            this.mustEdit = newValue;
        },
        shouldBeCompetitor(newValue){
            if(newValue != this.competitorModeAwaited) this.competitorModeAwaited = newValue;
        },
        disabled: function(newValue){ this.isDisabled = newValue; },
    }
})
</script>

<template>
    <div class="federal-number">
        <!--<span class="federalNumber" 
            :class="{'error': isError, 'warning': isWarning, isNotSetVariant: isNotSet, isValidVariant: !(isError || isWarning || isNotSet)}"
        >-->
        <span :class="`federalNumber ${badgeVariant}`">
            {{ displayNumber }}
        </span>
        <b-badge :disabled="isDisabled" v-if="this.isEditable" pill :variant="badgeVariant" @click="edit">!</b-badge>
    </div>
</template>

<style scoped>
    .federal-number{ display: inline-block; }
    .federalNumber.warning { color: var(--warning); }
    .federalNumber.error { color: var(--danger); }
    .federalNumber.success { color: var(--success); }
    .federalNumber.primary { color: var(--primary); }
    .federalNumber.secondary { color: var(--secondary); }
</style>